import React, {
  ChangeEvent,
  FC,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import { toast } from 'react-toastify'
import styled from '@emotion/styled/macro'
import { useTranslation } from 'react-i18next'
import MuiDialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useCallback } from 'react'
import StyledButton from 'core/components/StyledButton'
import { AppContext } from 'core/AppContext'
import { SmsOTCUser } from 'utils/types'
import eventEmitter, { events } from 'utils/eventEmitter'
import { PaymentMethods, PlotForm } from 'utils/formConfig'
import { callOtcVerifyApi, getActiveUser } from 'utils/formHelpers'
import { getResellerIdByCountry } from 'utils/userManagement'
import analytics from 'utils/analytics'

const PHONE_URL = process.env.REACT_APP_WHATSAPP_PHONE_URL

function onlyNumbers(str: string) {
  return str?.length === 0 || /^\d+$/.test(str)
}

const StyledDialog = styled(MuiDialog)``

const IconButtonStyle = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
  color: ${({ theme }) => theme.colors.palette.darkGray};
`

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 593px;
  width: 593px;
  background: #e8fbfa;
  padding: 25px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: 320px;
    height: 490px;
    padding: 30px;
  }
`

const SmsInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    gap: 11px;
  }

  body[dir='rtl'] & {
    flex-direction: row-reverse;
  }
`

const SmsInputBox = styled.div`
  display: flex;
  height: 135px;
  width: 400px;
  border-radius: 5px;
  border-color: #112a32;
  border-style: solid;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: 201px;
    height: 60px;
    border-color: #b7d2cd;
    border-width: 1px;
  }
`

const SmsNumber = styled.input`
  display: flex;
  height: 100%;
  width: 100%;
  border: none;
  padding: 0;
  font-size: 85px;
  font-weight: normal;
  border-color: #112a32;
  color: #112a32;
  background: transparent;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 32px;
  }
`

const TitleText = styled.span`
  font-size: 40px;
  font-weight: 900;
  line-height: 1.11;
  letter-spacing: -0.9px;
  text-align: center;
  color: #112a32;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 36px;
    font-weight: 900;
    line-height: 0.83;
    letter-spacing: -0.72px;
  }
`

const DescriptionText = styled.span`
  font-size: 20px;
  line-height: 1.07;
  padding: 8px 20px;
  letter-spacing: -0.6px;
  font-weight: normal;
  text-align: center;
  color: #112a32;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 16px;
    line-height: 1.11;
    letter-spacing: -0.36px;
    padding: 15px 15px 0px 15px;
  }
`

const BoldDescriptionText = styled(DescriptionText)`
  font-weight: bold;
  padding: 7px 0px 0px 2px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    padding: 7px 0px 0px 2px;
  }
`

const HelperText = styled(DescriptionText)`
  font-size: 16px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 14px;
  }
`

const BoldHelperText = styled(BoldDescriptionText)`
  font-size: 16px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 14px;
  }
`

const NeedHelpClickText = styled.a`
  font-size: 16px;
  cursor: pointer;
  line-height: 1.07;
  letter-spacing: -0.6px;
  font-weight: bold;
  text-align: center;
  color: #112a32;
  text-decoration: none;
  padding: 7px 0px 0px 2px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 14px;
    line-height: 1.11;
    letter-spacing: -0.36px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-self: center;
  margin-top: 50px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin-top: 20px;
    width: 100%;
  }
`

const SubmitButton = styled.a`
  width: 417px;
  height: 80px;
  border-radius: 40px;
  background-color: #052b33;
  color: white;
  font-size: 16px;
  align-content: center;
  text-align: center;
  cursor: pointer;
  text-decoration: none;

  @media (min-width: 1200px) {
    :hover {
      background-color: #05333c;
    }

    :active {
      background-color: #05333c;
    }
  }

  body[dir='rtl'] & {
    font-size: 20px;
    margin-top: 4px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 14px;
  }

  align-self: center;
`
interface InputRefs {
  inputRef: HTMLInputElement | null
}

interface SmsDialogProps {
  smsReqId: string
  isOpen: boolean
  paymentMethod: PaymentMethods
  couponCode: string | null
  lang?: string
  form?: PlotForm
  userPhoneNumber?: string
  onSuccess: (auth: string, user: SmsOTCUser) => void
  onClickResend: () => void
  onClose: () => void
}

const SmsDialog: FC<SmsDialogProps> = ({
  isOpen,
  smsReqId,
  couponCode,
  paymentMethod,
  lang,
  form,
  userPhoneNumber,
  onSuccess,
  onClickResend,
  onClose
}) => {
  const { t } = useTranslation('smsCode')
  const appContext = useContext(AppContext)
  const { t: toastT } = useTranslation('formScreen')
  const [smsInput, setSmsInput] = useState<string>('')
  const waObject = useMemo(() => ({ name: form?.contactForm.userName, crop: form?.cropForm.cropId, variety: form?.varietyForm.varietyType, date: new Date().toISOString() }), [form?.contactForm.userName, form?.cropForm.cropId, form?.varietyForm.varietyType])

  const inputResult = useMemo(
    () =>
      `${smsInput}`,
    [smsInput]
  )

  const inputRefs = useRef<InputRefs>({
    inputRef: null,
  })



  const handleChangeSmsInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      event.stopPropagation()
      const newString = event.target.value
      if (!onlyNumbers(newString)) {
        return
      }

      setSmsInput(newString);
    },
    []
  )

  const handleClickSubmit = useCallback(async () => {
    eventEmitter.emit(events.ENABLE_LOADING)
    try {
      const resellerId = `${getResellerIdByCountry(appContext.country)}`
      const res = await callOtcVerifyApi(
        inputResult,
        smsReqId,
        resellerId,
        couponCode,
        paymentMethod,
        lang || 'en'
      )
      eventEmitter.emit(events.DISABLE_LOADING)
      if (!res.isValid) {
        throw Error('Invalid payment method')
      }

      const userObject = await getActiveUser(
        res?.userResponse?.access_token_block?.access_token,
        Number(res?.userResponse?.user.id)
      )
      onSuccess(
        res?.userResponse?.access_token_block?.access_token,
        userObject.data as unknown as SmsOTCUser
      ) // pass auth key/data from response
    } catch (error) {
      eventEmitter.emit(events.DISABLE_LOADING)
      console.error(error)
      // @ts-ignore
      toast.error(toastT('general_server_error'))
    }
  }, [
    appContext.country,
    inputResult,
    smsReqId,
    couponCode,
    paymentMethod,
    lang,
    onSuccess,
    toastT
  ])

  const handleNeedHelpClick = () => {
    analytics.sendEvent(analytics.events.onboardingOTPClick)
  }

  return (
    <StyledDialog open={isOpen} PaperProps={{ className: 'sms-dialog' }}>
      <IconButtonStyle aria-label="close" onClick={onClose}>
        <CloseIcon />
      </IconButtonStyle>
      <ModalContainer>
        <TitleText>{t('sms_title')}</TitleText>
        <DescriptionText>
          {t('sms_description_1', { phone: userPhoneNumber })}
          <BoldDescriptionText>{t('please_enter_here')}</BoldDescriptionText>
        </DescriptionText>
        <DescriptionText>{t('sms_description_2')}</DescriptionText>
        <SmsInputContainer>
          <SmsInputBox>
            <SmsNumber
              autoFocus
              type={'number'}
              value={smsInput}
              onChange={(e) => handleChangeSmsInput(e)}
              autoComplete={'one-time-code'}
              autoCorrect={'off'}
              spellCheck={'false'}
              aria-haspopup="false"
              maxLength={4}
              ref={(ref) => {
                inputRefs.current.inputRef = ref
              }}
            />
          </SmsInputBox>
        </SmsInputContainer>
        <ButtonContainer>
          <SubmitButton
            onClick={handleClickSubmit}
            href={form ? `${PHONE_URL}?text=${toastT('wa_finish_onboarding', waObject)}` : undefined}
            target="_blank"
          >{t('sms_button_text')}</SubmitButton>
        </ButtonContainer>
        <HelperText>
          {t('code_not_arrive')}
          <BoldHelperText onClick={onClickResend}>
            {t('resend_code')}
          </BoldHelperText>
        </HelperText>
        <HelperText>
          {t('need_help')}
          <NeedHelpClickText
            onClick={handleNeedHelpClick}
            target="_blank"
            href={PHONE_URL + `?text=${t('need_help_message')}`}
          >
            {t('click_here')}
          </NeedHelpClickText>
        </HelperText>
      </ModalContainer>
    </StyledDialog>
  )
}

export default SmsDialog
