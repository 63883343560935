import React, {
  FC,
  useMemo,
  useCallback,
  ChangeEvent,
  useContext,
  useState
} from 'react'
import styled from '@emotion/styled/macro'
import { FormProps } from './FormScreen'
import RtlInput from 'core/components/RtlInput'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useRef } from 'react'
import { Country } from 'utils/formConfig'
import { AppContext } from 'core/AppContext'
import useInputFocusScroll from 'hooks/useInputFocusScroll'
import PhoneInput from 'core/components/PhoneInput'
import { matchIsValidTel, MuiTelInputInfo } from 'mui-tel-input'
import { OnboardingMode } from 'utils/types'
import { UserContext } from 'core/UserContext'
import CustomCheckbox from 'core/components/Checkbox'
import analytics from 'utils/analytics'

const TERMS_OF_USE_URL = process.env.REACT_APP_TERMS_OF_USE_URL
// const MAX_PLOT_NAME_LENGTH = 20
// const MIN_PLOT_NAME_LENGTH = 1

const getContactFormSchema = () => {
  return Yup.object().shape({
    name: Yup.string().min(1).required('Required')
    // plotName: Yup.string()
    //   .min(MIN_PLOT_NAME_LENGTH)
    //   .max(MAX_PLOT_NAME_LENGTH)
    //   .required('Required')
  })
}

const ContactDataContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    align-items: unset;
  }
`

const NameContentContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 5px;
  flex-direction: column;
  padding-bottom: 35px;
`

const CustomRtlInput = styled(RtlInput)`
  .MuiInput-input {
    font-size: 30px;

    @media (max-width: ${({ theme }) => theme.media.width.sm}) {
      font-size: 24px;
    }
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 45px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin-bottom: 50px;
    justify-content: none;
  }
`

const StyledCustomCheckbox = styled(CustomCheckbox)`
  > svg {
    height: 20px;
    width: 20px;

    @media (max-width: ${({ theme }) => theme.media.width.sm}) {
      height: 18px;
      width: 18px;
    }
  }
`

const TermsOfUseContainer = styled.div`
  display: block;
  justify-content: flex-start;
  letter-spacing: -0.5px;
  line-height: 1;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    letter-spacing: -0.4px;
  }
`

const ConfirmTermsText = styled.span`
  font-size: 20px;
  font-weight: 400;
  align-self: center;
  color: ${({ theme }) => theme.colors.sections.plotName.text};

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 14px;
  }
`

const ConfirmTermsTextLink = styled.a`
  font-size: 20px;
  font-weight: 400;
  align-self: center;
  color: ${({ theme }) => theme.colors.sections.plotName.text};
  text-decoration: underline;
  text-underline-offset: 3px;

  :hover {
    color: ${({ theme }) => theme.colors.sections.plotName.linkHover};
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 14px;
  }
`

const ContactForm: FC<FormProps> = ({ formData, handleChangeFormData }) => {
  const { i18n, t } = useTranslation('contactInfo')
  const { t: formScreenT } = useTranslation('formScreen')
  const { t: plotT } = useTranslation('plot')
  const userCountryData = useContext(AppContext)
  const userContext = useContext(UserContext)
  // const [plotNameValue, setPlotNameValue] = useState<string>(
  //   formData?.plotNameForm.plotName || ''
  // )
  const nameRef = useRef<HTMLInputElement>(null)
  const handleFocusName = useInputFocusScroll(nameRef)
  // const plotNameRef = useRef<HTMLInputElement>(null)
  // const handleFocusPlotName = useInputFocusScroll(plotNameRef)
  const name = useMemo(
    () => formData?.contactForm.userName || '',
    [formData?.contactForm.userName]
  )

  // const number = useMemo(
  //   () => formData?.contactForm.userNumber || '',
  //   [formData?.contactForm.userNumber]
  // )

  const formik = useFormik({
    initialValues: {
      name: name
      // plotName: plotNameValue
    },
    validationSchema: getContactFormSchema(),
    onSubmit: () => { }
  })

  const onChangeName = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleChangeFormData({
        ...formData,
        contactForm: {
          ...formData.contactForm,
          userName: event.target.value
        }
      })
      formik.setFieldValue('name', event.target.value)
    },
    [formData, formik, handleChangeFormData]
  )

  const onChangeNumber = useCallback(
    (value: string, info: MuiTelInputInfo) => {
      userCountryData.onChangeCountry(
        (info.countryCode as Country) || Country.Mexico
      )
      handleChangeFormData({
        ...formData,
        contactForm: {
          ...formData.contactForm,
          // userNumber: value
        }
      })
    },
    [formData, handleChangeFormData, userCountryData]
  )

  useEffect(() => {
    const hasFormikErrors = Object.keys(formik.errors).length
    const ready = name && !hasFormikErrors
    // const ready = name && !hasFormikErrors && matchIsValidTel(number)
    handleChangeFormData({
      ...formData,
      contactForm: {
        ...formData.contactForm,
        completed: !!(ready && formData.contactForm.termsAccepted)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChangeFormData, name, formik.errors])

  // const plotNameOnChange = useCallback(
  //   (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //     const eventPlotName = event?.target?.value
  //     handleChangeFormData({
  //       ...formData,
  //       plotNameForm: {
  //         ...formData.plotNameForm,
  //         plotName: formData.cropForm?.cropType,
  //         completed: !!(
  //           eventPlotName &&
  //           eventPlotName.length <= MAX_PLOT_NAME_LENGTH
  //         )
  //       }
  //     })
  //     setPlotNameValue(event.target.value)
  //     formik.setFieldValue('plotName', event.target.value)
  //   },
  //   [formData, handleChangeFormData, formik]
  // )

  const termsAccepted = useMemo(() => {
    return formData.contactForm.termsAccepted
  }, [formData.contactForm.termsAccepted])

  const handleClickTerms = useCallback(
    (event: ChangeEvent<HTMLInputElement>, cond: boolean) => {
      const hasFormikErrors = Object.keys(formik.errors).length
      // const ready = name && !hasFormikErrors && matchIsValidTel(number)
      const ready = name && !hasFormikErrors
      handleChangeFormData({
        ...formData,
        contactForm: {
          ...formData.contactForm,
          termsAccepted: cond,
          completed: !!(cond && ready)
        }
      })
    },
    [formik.errors, name, handleChangeFormData, formData]
  )

  const handleClickTermsLink = useCallback(() => {
    analytics.sendEvent(analytics.events.onboardingTermsAndConditionsClick)
  }, [])

  return (
    <ContactDataContainer id="contact-form">
      <NameContentContainer>
        <CustomRtlInput
          autoFocus
          onFocus={handleFocusName}
          isRtl={i18n.dir() === 'rtl'}
          value={name}
          inputRef={nameRef}
          error={!!formik.errors.name}
          onChange={onChangeName}
          label={t('your_name')}
          variant="standard"
        />
      </NameContentContainer>
      {/* <NameContentContainer>
        <CustomRtlInput
          onFocus={handleFocusPlotName}
          inputRef={plotNameRef}
          error={!!formik.errors.plotName}
          isRtl={i18n.dir() === 'rtl'}
          value={plotNameValue}
          onChange={plotNameOnChange}
          id="standard-basic"
          label={plotT('plot_name')}
          variant="standard"
        />
      </NameContentContainer> */}
      {/* <PhoneInput
        value={number}
        defaultCountry={userCountryData.country}
        onChange={onChangeNumber}
        label={t('phone')}
        lang={i18n.language}
        fullWidth
      /> */}
      {userContext.onboardingMode === OnboardingMode.New && (
        <CheckboxContainer>
          <StyledCustomCheckbox
            checked={termsAccepted}
            onChange={handleClickTerms}
          />
          <TermsOfUseContainer>
            <ConfirmTermsText>{formScreenT('confirm')}</ConfirmTermsText>
            <ConfirmTermsText>{'\u00A0'}</ConfirmTermsText>
            <ConfirmTermsTextLink
              target="blank"
              onClick={handleClickTermsLink}
              href={TERMS_OF_USE_URL}
            >
              {formScreenT('terms_of_privacy')}
            </ConfirmTermsTextLink>
          </TermsOfUseContainer>
        </CheckboxContainer>
      )}
    </ContactDataContainer>
  )
}

export default ContactForm
