// whatsApp helpers

import { TFunction } from "i18next"
import { PlotForm } from "./formConfig"

const PHONE_URL = process.env.REACT_APP_WHATSAPP_PHONE_URL


const dynamicallyCreateAnchorAndNavigate = (url: string) => {
    let anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.target = '_blank';
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
}

export const openWhatsAppWithMessage = (form: PlotForm, t: TFunction) => {
    const waObject = { name: form.contactForm.userName, crop: form.cropForm.cropId, variety: form.varietyForm.varietyType, date: new Date().toISOString() }
    const message = t('wa_finish_onboarding', waObject)
    dynamicallyCreateAnchorAndNavigate(`${PHONE_URL}?text=${message}`)
}
