import api from 'Api/ApiMethods'
import { TFunction } from 'i18next'
import { toast, ToastContent } from 'react-toastify'
import { Country, PlotForm } from './formConfig'
import {
  generatePolygonGeoJson,
  getCountryWeekdayBitmask,
  PLANT_ONBOARDING_USER_RESELLER_ID,
  PLANT_ONBOARDING_USER_VERIFY,
  varietyTypeConvertor
} from './formHelpers'
import {
  BotUser,
  GrowthMethod,
  IrrigationUnit,
  SlimGrower,
  SmsOTCUser,
  UtmData
} from './types'
import { constants } from 'chatbot-shared'
import { asyncDelay, isProdEnv } from './common'
import verySessionStorage from './vss'
import {
  END_RESERVE_HOUR,
  nonGeneralGrowTypeCropsList,
  nonGeneralGrowTypeVarietiesList,
  SOIL_SILT_LOAM,
  START_RESERVE_HOUR
} from './constants'

const LANG_LOCALES = {
  en: 'ENGLISH',
  he: 'HEBREW',
  es: 'SPANISH',
  fr: 'FRENCH',
  ar: 'ARABIC'
} as const

export const getFourYearsAgo = () => {
  const currentDate = new Date()
  const fourYearsAgo = new Date(currentDate)
  fourYearsAgo.setFullYear(currentDate.getFullYear() - 4)
  fourYearsAgo.setDate(fourYearsAgo.getDate() - 1)
  return fourYearsAgo.getTime()
}

export const updateUser = async (
  authToken: string,
  userObject: SmsOTCUser,
  utm: UtmData,
  formData: PlotForm,
  country: Country,
  lang: string,
  t: TFunction
) => {
  const userProfileId = verySessionStorage.get(PLANT_ONBOARDING_USER_VERIFY)
    ?.userObject?.userPreferences?.profileId

  const defaultUserProfileId =
    country === Country.Israel
      ? Number(process.env.REACT_APP_USER_PROFILE_ISRAEL)
      : Number(process.env.REACT_APP_USER_PROFILE_ROW)

  const language = (LANG_LOCALES as any)[lang]
  const supportedLocales = await api.getSupportedLocales()
  const updateUserObject: BotUser = {
    id: Number(userObject?.id),
    firstName: formData?.contactForm?.userName,
    lastName: formData?.contactForm?.userName,
    email: userObject?.email,
    companyRoleId: 12, // TBD: Check correct value wit product
    active: true,
    preferredMeasurementSystem: 'METRIC',
    gaRelevant: true,
    userApi: null,
    locale: supportedLocales?.data?.[language] || userObject?.locale,
    utm: utm as UtmData,
    userPreferences: {
      profileId: userProfileId || defaultUserProfileId,
      sendingTimePrefMap: {
        WA: {
          sendingTimePref: {
            hourFrom: START_RESERVE_HOUR,
            hourTo: END_RESERVE_HOUR,
            weekDays: getCountryWeekdayBitmask(country)
          }
        }
      },
      viewPref: {
        irrUnit: IrrigationUnit.M3HA
      }
    },
    // phone: formData.contactForm.userNumber.replace('+', '') || null
    phone: null
  }

  try {
    await api.updateUser([updateUserObject], authToken)
  } catch (e) {
    console.error('update user error')
    throw e
  }
}

export const getResellerIdByCountry = (country: Country): number => {
  const resellerId = verySessionStorage.getNumber(
    PLANT_ONBOARDING_USER_RESELLER_ID
  )

  if (resellerId) {
    return resellerId
  }

  if (!isProdEnv()) {
    return 2154
  }

  switch (country) {
    case Country.Spain:
      return 826
    case Country.Mexico:
      return 823
    case Country.India:
      return 993
    case Country.Morocco:
      return 1023
    case Country.Australia:
      return 992
    case Country.France:
      return 1100
    default:
    case Country.Israel:
      return 825
  }
}

export const getGrowerBody = (country: Country) => ({
  growerType: 'GROWER',
  resellerId: getResellerIdByCountry(country)
})

const getCategory = async (
  authToken: string,
  formData: PlotForm,
  growerId: string,
  cropId: string | undefined
) => {
  if (!formData) {
    return undefined
  }

  const responseCategory = await api.getCategory(authToken, growerId, cropId)
  const category = constants.crops.varieties[formData.cropForm.cropType]?.find((variety: { name: string, value: string | number, categoryTypeValue: string }) => variety.value === formData.varietyForm.varietyType);

  return (
    category?.categoryTypeValue ||
    responseCategory?.data?.[0] ||
    undefined
  )
}

const getCropProtocol = async (
  token: string,
  growerId: string,
  cropId: string | undefined,
  lat: number,
  lng: number,
  variety: string | undefined,
  category: string | undefined
) => {
  const protocolResponse = await api.getCropProtocol(
    token,
    growerId,
    cropId,
    lat,
    lng,
    variety,
    category
  )

  return protocolResponse
}

export const createPlot = async (
  authToken: string,
  formData: PlotForm,
  growerId: number,
  country: Country,
  t: TFunction
) => {
  const center = formData.plotLocationForm.plotLocation
  const areaInDunam = formData.plotLocationForm.plotAreaAmount
  // const area = formData.plotLocationForm.plotAreaAmount /* keep code for now if we will return the polygon area calculator */
  // const areaInDunam = country === Country.Israel ? area : (area || 0) * 10 /* keep code for now if we will return the polygon area calculator */ 
  const shouldAttachIWSensor = (areaInDunam || 0) >= 3
  let plot
  let getTokenStringTest = ''
  try {
    const category = await getCategory(
      authToken,
      formData,
      `${growerId}`,
      `${formData.cropForm?.cropId}`
    )
    const variety =
      varietyTypeConvertor(formData.varietyForm.varietyType) || undefined
    // for test only - please remove after fix
    getTokenStringTest = `${authToken}`
    // end of for test only - please remove after fix
    const cropProtocolResponse = await getCropProtocol(
      authToken,
      `${growerId}`,
      `${formData.cropForm?.cropId}`,
      center?.latitude || 0,
      center?.longitude || 0,
      variety,
      category
    )
    const initialStageDate = new Date(
      formData.cropBlossomForm.initialStageDate || new Date().getTime()
    ).getTime()
    const cropProtocolId = cropProtocolResponse?.data?.id
    const growthMethodResponse = await api.getGrowingTypes(
      authToken,
      cropProtocolId,
      getFourYearsAgo()
    )
    const growthMethod = growthMethodResponse?.data?.[0]
    const plotBody = {
      growerId,
      plantTime: getFourYearsAgo(),
      initialStageDate,
      category,
      variety,
      cropProtocolId,
      growingType: 'OPEN_FIELD',
      area: { unit: 'DUNAM', val: areaInDunam },
      sampleFrequency: 'NORMAL',
      serviceLevel: 'BDI',
      startTime: getFourYearsAgo(),
      name: `${formData.cropForm?.cropType}_1`,
      crop: formData.cropForm?.cropId,
      soil: SOIL_SILT_LOAM, // Silt Loam
      latitude: center?.latitude,
      longitude: center?.longitude,
      irrigationMethod: 'DRIP',
      initialStage: {},
      growthMethod:
        nonGeneralGrowTypeCropsList.includes(formData?.cropForm.cropType) &&
          nonGeneralGrowTypeVarietiesList.includes(variety || '')
          ? GrowthMethod.Mulch
          : growthMethod,
      startSeason: initialStageDate,
      geojson: generatePolygonGeoJson(formData.plotLocationForm.plotBounds)
    }

    await asyncDelay(1000) // make sure java server finish process
    const createPlotResponse = await api.createPlot(
      authToken,
      `${growerId}`,
      process.env.REACT_APP_IRRIWATCH_COMPANY_UUID || '',
      plotBody,
      shouldAttachIWSensor
    )

    await asyncDelay(1000) // make sure java server finish process
    plot = createPlotResponse?.data
    // if (formData.plotUnitsForm.selectedUnit === IrrigationUnit.Time) {
    //   const getParamsResponse = await api.getPlotParams(authToken, plot?.id)
    //   const plotParams = getParamsResponse?.data
    //   plotParams.params.NominalFlow =
    //     formData?.plotUnitsValuesForm.nominalFlow || 0 // update plot will fail if we send null or undefined
    //   await api.postPlotParams(authToken, plot?.id, plotParams.params)
    // }

    return { plot }
  } catch (e) {
    let plotCreationError = getTokenStringTest
    try {
      plotCreationError = `${plotCreationError} |***| ${JSON.stringify(e)}`
    } catch {
      plotCreationError = (e as Error).toString()
    }

    console.error('create plot error', e)
    toast.error(t('general_server_error') as ToastContent<unknown>)
    return { plot, plotCreationError }
  }
}

// process newly created user by updating his data, creating a grower connect to the user , and then create a plot in that grower
export const processUser = async (
  authToken: string,
  userObject: SmsOTCUser,
  utm: UtmData,
  formData: PlotForm,
  country: Country,
  lang: string,
  t: TFunction
) => {
  let growerId
  let plot
  try {
    await updateUser(authToken, userObject, utm, formData, country, lang, t)
    const grower = await api.createGrower(
      authToken,
      userObject.id,
      formData?.contactForm?.userName || userObject?.id,
      country
    )
    const growerObject: SlimGrower = grower?.data
    growerId = growerObject?.id
    const { plot: createdPlot, plotCreationError } = await createPlot(
      authToken,
      formData,
      growerObject.id,
      country,
      t
    )
    plot = createdPlot
    await api.putOneTimeMessages(
      authToken,
      userObject.id,
      growerObject.id.toString(),
      plot.id
    )
    return { plot, growerId, plotCreationError }
  } catch (e) {
    toast.error(t('general_server_error') as ToastContent<unknown>)
    return { plot, growerId }
  }
}

export const getCheckoutSession = async (
  authToken: string,
  sessionId: string,
  growerId: number,
  userId: number,
  country: string,
  t: TFunction
) => {
  let session
  try {
    session = await api.getCheckoutSession(
      authToken,
      sessionId,
      growerId,
      userId,
      country
    )
    return session.data
  } catch (e) {
    toast.error(t('general_server_error') as ToastContent<unknown>)
    return session
  }
}
